$ancestry-header-gutter: 5px;

.ancestry-header {
  background: white;

  @include media-breakpoint-down(sm) {
    // @media (orientation: landscape) {
    pointer-events: none;

    a,
    button {
      position: relative;
      z-index: 3;
      pointer-events: all;
    }

    // }
  }

  .row {
    height: 60px;
    align-items: center;
  }

  nav a:focus {
    @include media-breakpoint-up(md) {
      border-width: 0.1rem;
      border-color: $brand-high-contrast-color;
      border-style: solid;
      padding: $spacer * 0.5 - 0.1 $spacer - 0.1;
      @include bootstrap-focus-shadow();
    }
  }

  nav a,
  nav a:focus,
  nav a:active {
    color: $brand-primary-color;
  }

  nav a.active {
    @include media-breakpoint-up(md) {
      color: white !important;
      background: $brand-primary-color !important;
    }
  }

  .nav-name {
    @include media-breakpoint-down(md) {
      display: none;
    }

    color: $brand-primary-color;
  }

  .nav-sm-name {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    color: $brand-primary-color;
  }
}

.ancestry-header + .auth-content {
  padding: 0 !important;
}

.inlineText {
  white-space: nowrap;
}

.embed-responsive,
.embed-responsive-item,
.mapboxgl-canvas {
  height: calc(100vh - 60px) !important;
  width: 100vw;

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 129px) !important;
  }
}

.ancestry {
  position: relative;

  .ancestry-region-description p {
    margin-top: $spacer;
  }

  .ancestry-panel {
    position: absolute;
    top: 30px;
    left: 34px;
    z-index: 2;
    width: 100%;
    max-width: 375px;
    max-height: calc(100% - 60px);
    display: flex;
    transition-duration: 0.6s;

    @include media-breakpoint-down(sm) {
      left: 0px;
      max-width: none;
      top: auto;

      &.centered {
        top: 55%;
        height: 45%;
      }

      &.closed {
        top: 100%;
        transform: translateY(-53px);
      }

      &.opened {
        top: 50px;
        height: calc(100% - 50px);
        max-height: initial;
      }
    }

    .ancestry-table {
      background: white;
      box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding-top: 10px;
        height: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .slider-bar {
        display: none;

        @include media-breakpoint-down(sm) {
          height: 26px;
          width: 100%;
          position: absolute;
          top: -1px;
          left: 0;
          display: block;
          background: white;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;

          .bar {
            margin: 4px auto;
            border-radius: 3px;
            background: #b4bdc1;
            width: 40px;
            height: 6px;
          }
        }
      }

      .title {
        line-height: 1rem;
        font-weight: 700;
        color: $brand-primary-color;
        padding: 1rem 1rem 0.5rem 1rem;
      }

      .content-table {
        overflow-y: auto;
        padding: 0 1rem 1rem 1rem;
        scrollbar-width: thin;

        @include media-breakpoint-down(sm) {
          overflow-y: auto;
        }

        .tabs {
          display: flex;
          margin: 0 -1rem;

          .level {
            border-bottom: 1px solid #7daed4;
            color: #7daed4;
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            cursor: pointer;

            &:hover:not(.disabled, .active) {
              color: $brand-primary-color;
              background: #f2f7fa;
            }

            &.active {
              cursor: default;
              color: $brand-primary-color;
              border-bottom: 3px solid $brand-primary-color;
            }

            &.disabled {
              cursor: default;
              color: #b4bdc1;

              .icon {
                margin-right: 4px;
                width: 10px;
                height: 13px;
                margin-top: -4px;

                path {
                  fill: #b4bdc1 !important;
                }
              }
            }
          }
        }

        .level-text {
          text-align: left;
          line-height: 20px;
          padding-top: 0.5rem;

          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
          }
        }

        .tabs + .level-text {
          padding-top: 1rem;
        }

        .ancestry-list {
          .ancestry-region {
            .ancestry-table-button {
              display: flex;
              align-items: center;
              height: 36px;
              font-size: 14px;
              line-height: 16px;
              border-radius: 4px;
              cursor: pointer;

              &.disabled {
                cursor: default;
                color: #6d777b;
              }

              .color-legend {
                margin-right: 8px;

                div {
                  border-radius: 50%;
                  width: 14px;
                  height: 14px;
                }
              }

              .region-name {
                text-align: left;
              }

              .percentage {
                margin-left: auto;
                padding-left: 10px;
                padding-right: 10px;
              }

              &.selected {
                border-bottom: 1px solid $brand-primary-color !important;
                background: #f2f7fa;
                font-weight: bold;
              }

              &.selected-ligth {
                background: #f2f7fa;
              }

              &.ancestry-table-button-level1 {
                margin-top: 0.5rem;
                padding-left: 10px;
                font-weight: 700;

                .color-legend {
                  display: none;
                }

                .percentage {
                  font-weight: 700;
                }

                &:hover:not(.disabled) {
                  opacity: 0.8;
                }

                &.selected {
                  border: 3px solid $brand-primary-color !important;
                }

                &.selected-ligth {
                  border: 0.5px solid $brand-primary-color;
                }
              }

              &.ancestry-table-button-level2 {
                padding-left: 1rem;
                border-bottom: 1px solid #bfd6e8;
                border-radius: 0;

                .percentage {
                  padding-right: 1rem;
                }

                &:hover:not(.disabled) {
                  background: #e6eff6;
                }
              }

              &.ancestry-table-button-level3 {
                margin-left: 2rem;
                border-bottom: 1px solid #bfd6e8;
                border-radius: 0;
                font-size: 12px;
                line-height: 14px;

                .percentage {
                  padding-right: 2rem;
                }

                &:hover:not(.disabled) {
                  background: #e6eff6;
                }
              }
            }
          }
        }
      }
    }

    .ancestry-zoom-out {
      // $zoomButtonSize: 25px;
      border: 0;
      padding: 12px;
      background: none;
      position: absolute;
      top: -20px;
      right: 5px;
      font-weight: normal;
      color: $brand-primary-color;

      &.inactive {
        color: #888;
        pointer-events: none;
      }

      @include media-breakpoint-down(sm) {
        //    right: 1.5rem;
        //    top: 1.5rem;
      }

      // svg {
      //     width: $zoomButtonSize;
      //     height: $zoomButtonSize;
      //     fill: $brand-primary-color;
      // }
      &:focus {
        outline: none !important;
        border: none m !important;
        box-shadow: none !important;
      }
    }
  }
}

.mapboxgl-control-container {
  display: none;
}

.ancestry-locked-map-cover {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: $background-primary-color;
  opacity: 0.5;
}

.ancestry-locked {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 50%;
  min-width: 400px;

  @include media-breakpoint-down(sm) {
    position: static;
    width: 100%;
    min-width: 100%;
    transform: none;
  }

  .ancestry-locked-box-wrapper {
    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 350px;
      left: 0;
      width: calc(100% - 2rem);
      margin: 0 1rem;
    }

    .ancestry-locked-icon-container {
      & svg {
        width: 250px;
        height: 250px;

        @include media-breakpoint-down(sm) {
          position: absolute;
          transform: translate(-50%, -100%);
          width: 200px;
          height: 200px;
          z-index: 2;
        }
      }
    }

    .ancetry-locked-content-title {
      text-align: center;
      margin-bottom: 1rem;
      color: $brand-primary-color;
    }

    .ancetry-locked-content-subtitle {
      text-align: center;
      margin-bottom: 1rem;
      color: #666666;
    }

    .ancetry-conset-content-subtitle {
      text-align: left;
      margin-bottom: 1rem;
      color: #666666;
    }

    .ancestry-consent-content {
      align-items: center;
      flex-direction: row;
    }

    .ancestry-locked-content {
      background-color: white;
      border-radius: 0 0 0.5rem 0.5rem;
      padding: 25px;
      position: relative;
      text-align: left;

      @include media-breakpoint-down(lg) {
        width: 130%;
        left: -15%;
        margin-top: 30px;
      }

      @include media-breakpoint-down(md) {
        width: 140%;
        left: -20%;
        margin-top: 20px;
      }

      @include media-breakpoint-down(sm) {
        width: calc(100% - 2rem);
        margin: 0 1rem;
        position: static;
      }
    }

    .ancestry-locked-content::before {
      content: "";
      width: 100%;
      position: absolute;
      height: 125px;
      z-index: -1;
      border-radius: 0.5rem 0.5rem 0 0;
      transform: translateY(-125px);
      left: 0;
      top: 0;
      background-color: white;

      @include media-breakpoint-down(sm) {
        content: "";
        width: calc(100% - 2rem);
        margin: 0 1rem;
        position: absolute;
        height: 100px;
        z-index: 1;
        border-radius: 0.5rem 0.5rem 0 0;
        transform: translateY(-100px);
        left: 0;
        top: 0;
        background-color: white;
      }
    }
  }

  .ancestry-locked-box {
    position: absolute;
    bottom: 0;
    width: 70%;
    margin: 0 auto;
    left: 15%;

    @include media-breakpoint-down(sm) {
      position: relative;
      left: 0;
      margin: 0;
      margin-top: 140px;
      width: 100%;
    }
  }

  .ancestry-locked-suggested-surveys {
    width: 100%;
    position: absolute;
    margin-top: 40px;
    left: 0;
    padding-bottom: 150px;

    h2 {
      text-align: left;
      font-size: 16px;
    }

    @include media-breakpoint-down(lg) {
      width: 130%;
      left: -15%;
      margin-top: 30px;
    }

    @include media-breakpoint-down(md) {
      width: 140%;
      left: -20%;
      margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - 2rem);
      margin: 1rem;
      position: static;
    }

    svg {
      width: 350px;
      height: 350px;

      @include media-breakpoint-down(sm) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 200px;
      }
    }
  }

  .ancestry-locked-btns {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn-link {
      padding: 13px 30px 13px 16px;
      margin: 0;
      color: $brand-primary-color;
      background: transparent;
      border-radius: none;
      text-decoration: none;

      &:hover {
        color: $brand-primary-color-dark;
      }

      &:focus {
        @include bootstrap-focus-shadow();
      }
    }
  }
}

.ancestry-panel-locked {
  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
    position: absolute !important;
    top: 80vh !important;
    // background: red !important;
  }

  @include media-breakpoint-down(xs) {
    top: 100vh !important;
  }
}

.ancestry-referral-banner {
  position: absolute;
  top: 135px;
  z-index: 3;
  width: 100%;

  .center-section {
    margin: 0 auto;
  }
}
