$landing-space-s: 1rem;
$landing-space-m: 2rem;
$landing-space-l: 3rem;
$landing-space-xl: 4rem;

$landing-section-space: 6rem;

$landing-mid-body-bottom-background: #dee8ef;
$landing-mid-body-gradient: linear-gradient(
  to bottom,
  #ffffff 0%,
  $landing-mid-body-bottom-background 100%
);

.unauth-landing {
  background: white;
}

.landing-page {
  width: 100%;
}

.landing-wrapper {
  margin-top: -2px !important;
  color: #000;
}

.landing-content {
  margin: 0 auto;
  width: 70%;
  max-width: 1095px;
  position: relative;
  z-index: 5;
}

.landing-content-banner {
  margin: 0 auto;
  width: 80%;
  // max-width: 1095px;
  position: relative;
  z-index: 5;

  @include media-breakpoint-down(sm) {
    width: 90%;
  }
}

.landing-header-wrapper {
  background: $brand-primary-color;
  color: white;
  padding: 10rem 0;
  @include media-breakpoint-down(md) {
    padding: 6rem 0;
  }
}

.landing-header-wrapper p {
  max-width: 500px;
}

.landing-header-links {
  margin-top: $landing-space-m;

  a.landing-header-button {
    background: white;
    color: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: $landing-space-m;

    &:hover {
      background: #eee !important;
    }
    &:focus {
      padding: 11px 27px;
      border: 1px solid $brand-primary-color;
      @include bootstrap-focus-shadow(white);
    }
  }

  a.landing-header-link {
    color: white;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: bolder;

    &:hover {
      color: #ccc !important;
    }
    &:focus {
      @include bootstrap-focus-shadow(white);
    }
  }
}

.icon-arrow-right {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -3px;
  margin-left: $landing-space-m;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: $brand-primary-color;
    position: absolute;
    transform-origin: right center;
  }

  &::before {
    transform: translate(0, 1px) rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.landing-body-wrapper {
  padding: $landing-section-space 0 0;
  color: #000;

  h3 {
    color: #000;
    margin-bottom: $landing-space-s;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem;
    }
  }
  p {
    @include media-breakpoint-down(sm) {
      margin-bottom: $landing-space-m;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.icon-box {
  width: 82px;
  height: 82px;
  margin-bottom: 1rem;
  border: 1px solid $brand-primary-color;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    margin: 0 auto 0.5rem;
  }
}

.landing-hero-wrapper {
  clip-path: ellipse(110% 50% at 50% 45%);
  width: 100%;
  max-width: 100%;
  color: #222;
  padding: $landing-section-space $landing-space-m $landing-section-space;
  background: $landing-mid-body-gradient;

  h2,
  h3 {
    color: #222;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
    font-weight: 300;

    > small {
      display: inline-block;
      margin-right: 8px;
    }
  }
  ul {
    list-style: none;
    padding: 0;

    > li {
      margin-bottom: 0.75rem;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: $landing-space-m $landing-space-m $landing-space-xl;
    text-align: center;
    .col-sm:first-child {
      display: none;
    }
  }
}

.landing-page-character {
  width: 100%;
  max-width: 420px;
}

.landing-tick {
  width: 16px;
  display: inline-block;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 12px;
}

.landing-hero-links {
  margin-top: $landing-space-m;

  a.landing-hero-button {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }

    &:focus {
      padding: 11px 27px;
      border: 1px solid $landing-mid-body-bottom-background;
    }
  }

  a.landing-hero-link {
    color: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
    font-weight: bolder;

    &:hover {
      color: #107ed4 !important;
    }
  }
}

.landing-video-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  border: 1px solid $brand-primary-color;
  border-radius: 50%;
  margin-left: 0.5rem;

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: relative;
    left: 1px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid $brand-primary-color;
  }
}

.landing-about-wrapper {
  padding: $landing-space-m 0 0;
  color: #000;

  h2,
  h3,
  h4 {
    color: #000;
    font-weight: normal !important;
  }
  h2 {
    margin-bottom: $landing-space-s;
  }
  h3 {
    margin-bottom: $landing-space-m;
  }
  h4 {
    margin-bottom: $landing-space-l;
  }

  @include media-breakpoint-down(xs) {
    .col-about:first-child {
      margin-bottom: $landing-space-s;
    }
    h3,
    h4 {
      margin-bottom: $landing-space-s;
    }
  }
}

.landing-referral-banner-wrapper {
  padding: $landing-section-space 0 0 0;
  color: #000;
  margin-top: $landing-space-s;
  .landing-content-banner {
    h3 {
      color: $brand-primary-color;
      font-weight: bold;
      margin-top: $landing-space-l;
      margin-bottom: $landing-space-l;
      margin-right: 200px;
      font-size: 32px;
    }
  }

  .referral-buttons-wrapper {
    gap: 1rem;
  }

  .referral-links-wrapper {
    display: flex;
    gap: 1rem;
  }

  @include media-breakpoint-down(sm) {
    background-position:
      -40% -490%,
      212% 550%;

    .landing-content-banner {
      h3 {
        font-size: 18px;
        margin-right: 0;
        font-weight: bold;
      }
    }

    .referral-links-wrapper {
      display: none;
    }

    .referral-buttons-wrapper {
      gap: 1rem;
      .primary-invert {
        display: none;
      }

      .btn-primary {
        background-color: #fff !important;
        border: #fff !important;
        color: $brand-primary-color !important;
        &:hover {
          background-color: $brand-primary-color !important;
          border: $brand-primary-color !important;
          color: #fff !important;
        }
      }
    }
  }
}

.landing-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $landing-space-m $landing-space-m;
  background-color: $brand-primary-color;
  margin-top: 6rem;
  .landing-content {
    display: flex;
    color: white !important;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-position:
      130% 197%,
      -30% -103%;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
      background-position:
        -40% -490%,
        212% 550%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  input,
  input::placeholder {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    font-size: 14px !important;
    color: $brand-primary-color !important;
    font-weight: lighter !important;
    flex: 1;
  }

  @include media-breakpoint-down(sm) {
    .col-sm,
    .landing-footer-text {
      text-align: center !important;
    }
    .landing-footer-text {
      margin-bottom: 1rem;
    }
  }
}

.landing-footer-input-wrapper {
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: $border-radius;
  white-space: nowrap;
  display: flex;
  width: 100%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.landing-footer-input-icon {
  appearance: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: transparent;
  border-right: none;
  border-top: none;
  border-bottom: none;
  height: 80%;
  width: 50px;
  vertical-align: middle;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.886);
  border-radius: 0;

  > svg {
    height: 60%;
    width: auto;
  }

  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.landing-footer-input-btn {
  appearance: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: transparent;
  border-right: none;
  border-top: none;
  border-bottom: none;
  // width: 100px;
  vertical-align: middle;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.886);
  border-radius: 0;
  color: white;

  > svg {
    height: 60%;
    width: auto;
  }

  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}
.landing-footer-text {
  display: inline-block;
}

.unauth-header-landing-menu {
  position: absolute;
  z-index: 8;
  top: 20px;
  right: 25px;
  transition: 0.25s ease;

  @include media-breakpoint-down(sm) {
    background: $brand-primary-color;
    top: 0;
    right: 0;
    min-height: 100vh;
    z-index: 8;
    width: 100%;
    padding: 0 $landing-space-s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &._open {
      visibility: visible;
    }
    &._closed {
      visibility: hidden;
      transform: translateX(100%);
    }
  }
}
.unauth-header.logged {
  .landing-header-menu-links,
  .landing-header-menu-buttons,
  .landing-header-menu-language {
    vertical-align: top;
  }
}

.landing-header-menu-links,
.landing-header-menu-buttons,
.landing-header-menu-language {
  display: inline-block;
  vertical-align: middle;

  @include media-breakpoint-down(sm) {
    display: block;
    margin-right: 0 !important;
    width: 100%;
  }
}

.landing-header-menu-language {
  margin-right: $landing-space-l;
  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: $landing-space-m;
    .dropdown-toggle,
    .header-languages .dropdown-item {
      font-size: 18px !important;
    }
  }
}

.landing-header-menu-links {
  margin-right: $landing-space-l;
}

.landing-header-menu-links > a,
.landing-header-menu-buttons > a {
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: $landing-space-m;
    font-size: 18px;
  }
}

.landing-header-menu-links > a {
  color: white;
  font-weight: bolder;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a {
  color: white;
  background: transparent;
  border: 1px solid white;
  font-weight: 300;
  border-radius: $border-radius;
  padding: 4px 10px;
  margin-right: $landing-space-s;

  @include media-breakpoint-down(sm) {
    padding: $landing-space-s;
    margin-bottom: $landing-space-s;
  }

  &:focus {
    padding: 3px 9px;
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a:hover,
.landing-header-menu-buttons > a._highlighted {
  background: white !important;
  color: $brand-primary-color !important;

  &:focus {
    padding: 3px 9px;
    border: 1px solid $brand-primary-color;
    @include bootstrap-focus-shadow(white);
  }
}

.landing-header-menu-buttons > a._highlighted:hover {
  background: rgba(255, 255, 255, 0.85) !important;
}

.landing-header-menu-hamburger {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 9;
  display: none;

  > .hamburger-strip {
    width: 32px;
    height: 4px;
    background: white;
    display: block;
    position: absolute;
    border-radius: 3px;
    transition: 0.25s ease;
    transform-origin: left center;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 14px;
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }

  &._open > .hamburger-strip:nth-child(1) {
    transform: translateY(2.5px) rotate(45deg);
  }
  &._open > .hamburger-strip:nth-child(2) {
    opacity: 0;
  }
  &._open > .hamburger-strip:nth-child(3) {
    transform: translateY(-2.5px) rotate(-45deg);
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.landing-wrapper .unauth-header-logo {
  position: relative;
  z-index: 9;
}
